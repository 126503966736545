import React, { useContext, createContext, useEffect, useState, useCallback, useMemo } from 'react'
import zionDebug from '@fs/zion-debug'
import { captureException, setExtras } from '@sentry/react'

const debug = zionDebug('surname:AdobeTargetProvider:')
export const ATContext = createContext({})

/**
 * Filters out unique mbox names from a new list of targetMboxes that are not already present in the previous list.
 */
function getUniqueTargetMboxes(newTargetMboxes, prevTargetMboxes) {
  const newMboxNames = new Set(newTargetMboxes.map((mbox) => mbox.mboxName))
  const prevMboxNames = new Set(prevTargetMboxes.map((mbox) => mbox.mboxName))
  const mboxNamesToAdd = [...newMboxNames].filter((mboxName) => !prevMboxNames.has(mboxName))
  return newTargetMboxes.filter((mbox) => mboxNamesToAdd.includes(mbox.mboxName))
}

/**
 * Sets the Adobe Target status to 'loaded' upon receiving a custom event indicating the Adobe Target library has loaded.
 */
function handleAdobeTargetLibraryLoaded(setAtStatus, lifecycleStatus) {
  document.addEventListener('at-library-loaded', (e) => {
    if (!lifecycleStatus.unloading) {
      debug('AT: Loaded event:', e)
      setAtStatus('loaded')
    }
  })
}

/**
 * Handles fetching offers for each mbox when the Adobe Target status is 'loaded'. Updates the results state with the fetched data.
 */
function fetchAndUpdateTargetMboxOffers({
  adobeTargetStatus,
  targetMboxes,
  targetMboxResults,
  setTargetMboxResults,
  setLoading,
}) {
  if (
    adobeTargetStatus === 'loaded' &&
    targetMboxes.length > 0 &&
    !targetMboxes.some(({ mboxName }) => targetMboxResults[mboxName])
  ) {
    const promises = targetMboxes.map(({ mboxName, exps }) => {
      // Skip if the mbox offer has already been read
      if (targetMboxResults[mboxName]) {
        return Promise.resolve(targetMboxResults[mboxName])
      }

      return new Promise((resolve, reject) => {
        window?.adobe?.target?.getOffer({
          mbox: mboxName,
          params: {},
          success(response) {
            debug('at.success.response', response)
            const expItem = response.find((item) => {
              return item.content && item.content.some((subItem) => exps.indexOf(subItem.exp) !== -1)
            })
            const exp = expItem?.content?.find((subItem) => exps.indexOf(subItem.exp) !== -1)?.exp
            const isOn = Boolean(exp)
            resolve({ mboxName, exp, isOn, status: 'success' })
          },
          experienceCloud: {
            analytics: {
              logging: 'server_side',
            },
          },
          error(errorStatus, error) {
            console.error('getOffer error: ', error, errorStatus)
            setExtras({ status: errorStatus, mboxName })
            captureException(error)
            // eslint-disable-next-line prefer-promise-reject-errors
            reject({ mboxName, exps, status: 'error' })
          },
        })
      })
    })

    Promise.allSettled(promises).then((results) => {
      const newAbTests = { ...targetMboxResults }
      results.forEach((result) => {
        if (result.status === 'fulfilled') {
          const { isOn, exp, status, mboxName } = result.value
          newAbTests[mboxName] = { exp, isOn, status }
        } else {
          const { isOn = false, exp, status, mboxName } = result.reason
          newAbTests[mboxName] = { status, exp, isOn }
        }
      })
      setTargetMboxResults(newAbTests)
      setLoading(false)
    })
  }
}

/**
 * Provides context for Adobe Target functionalities, managing the state for targetMboxes, their results, and loading status.
 * @param {Object} props - The props object containing children components to be rendered within this provider.
 * @returns {JSX.Element} The provider component with Adobe Target context.
 */
export default function AdobeTargetProvider({ children }) {
  const [adobeTargetStatus, setAdobeTargetStatus] = useState('loading')
  const [targetMboxes, setTargetMboxes] = useState([])
  const [targetMboxResults, setTargetMboxResults] = useState({})
  const [loading, setLoading] = useState(true)

  const isBot = navigator.userAgent.includes('prerender')
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

  const addTargetMboxes = useCallback((newTargetMboxes) => {
    setTargetMboxes((prevTargetMboxes) => {
      const uniqueTargetMboxes = getUniqueTargetMboxes(newTargetMboxes, prevTargetMboxes)
      return uniqueTargetMboxes.length === 0 ? prevTargetMboxes : [...prevTargetMboxes, ...uniqueTargetMboxes]
    })
  }, [])

  useEffect(() => {
    const lifecycleStatus = { unloading: false }
    if (isBot || isSafari) {
      debug('Skipping Adobe Target for prerender bots and safari !', { isBot, isSafari })
      setAdobeTargetStatus('skipped')
      setLoading(false)
    } else {
      handleAdobeTargetLibraryLoaded(setAdobeTargetStatus, lifecycleStatus)
      fetchAndUpdateTargetMboxOffers({
        adobeTargetStatus,
        targetMboxes,
        targetMboxResults,
        setTargetMboxResults,
        setLoading,
      })
    }
    return () => {
      lifecycleStatus.unloading = true
    }
  }, [adobeTargetStatus, targetMboxes, targetMboxResults, isBot, isSafari])

  const contextValue = useMemo(
    () => ({ status: adobeTargetStatus, targetMboxResults, loading, addTargetMboxes }),
    [adobeTargetStatus, targetMboxResults, loading, addTargetMboxes]
  )

  debug('value:', contextValue)

  return <ATContext.Provider value={contextValue}>{children}</ATContext.Provider>
}

/**
 * Custom hook to add new targetMboxes to the Adobe Target context and retrieve the context state.
 * @param {Array} newTargetMboxes - The new targetMboxes to be added to the context.
 * @returns {Object} The current state of the Adobe Target context excluding the `addTargetMboxes` function.
 */
export function useAdobeTarget(newTargetMboxes) {
  const { addTargetMboxes, ...context } = useContext(ATContext)

  if (typeof context === 'undefined') {
    throw new Error('This hook must be used within an AdobeTargetProvider')
  }

  useEffect(() => {
    if (newTargetMboxes && newTargetMboxes.length > 0) {
      addTargetMboxes(newTargetMboxes)
    }
  }, [addTargetMboxes, newTargetMboxes])

  return context
}
